import { useEffect, useState } from 'react'

import { getIconsExplanation } from 'data/api'
import { IconExplanationInnerDetailsModel, PhotoModel } from 'types/models'
import { transformItemBadgesExplanation } from 'data/transformers/icon-badges'

type ItemDetails = {
  title: string
  brandDetails: { title: string; url: string }
  size: string
  photo: PhotoModel
}

function useIconsExplanations(itemId: number, show: boolean) {
  const [iconBadges, setIconBadges] = useState<Array<IconExplanationInnerDetailsModel>>([])
  const [itemDetails, setItemDetails] = useState<ItemDetails | undefined>(undefined)
  const [isOpen, setIsOpen] = useState(false)
  const [isNotificationDisplayed, setIsNotificationDisplayed] = useState(false)

  const handleNotificationClose = () => {
    setIsNotificationDisplayed(false)
  }

  useEffect(() => {
    if (!show) {
      setIsOpen(false)
    }

    async function getModalData() {
      const response = await getIconsExplanation(itemId)

      if ('errors' in response) {
        setIsNotificationDisplayed(true)

        return
      }

      const transformedResponse = transformItemBadgesExplanation(response.item_badges_explanation)

      setIconBadges(transformedResponse.iconBadges)
      setItemDetails({
        title: transformedResponse.title,
        brandDetails: transformedResponse.brand,
        size: transformedResponse.sizeTitle,
        photo: transformedResponse.photo,
      })
      setIsOpen(true)
    }

    if (show) {
      getModalData()
    }
  }, [show, itemId])

  return {
    iconBadges,
    itemDetails,
    isOpen,
    isNotificationDisplayed,
    handleNotificationClose,
  }
}

export default useIconsExplanations
