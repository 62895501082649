'use client'

import { KeyboardEvent, AriaAttributes, ReactNode } from 'react'
import classNames from 'classnames/bind'
import { Icon, Spacer, Text } from '@vinted/web-ui'
import { Heart16, HeartFilled16 } from '@vinted/monochrome-icons'

import { onA11yKeyDown } from 'data/utils/a11y'
import { getTestId } from 'libs/utils/testId'

type Props = {
  favourited: boolean
  count: number
  onClick?: () => void
  titleIcon?: string
  ariaIcon?: AriaAttributes
  experimentalIndicator?: ReactNode
  /**
   * Adds data-testid attribute to parent and children components.
   * When used, --favourite-icon and --favourite-count suffixes applied accordingly.
   */
  testId?: string
}

const ItemBoxFavourite = ({
  favourited = false,
  count = 0,
  onClick,
  titleIcon,
  ariaIcon,
  experimentalIndicator,
  testId,
}: Props) => {
  const handleKeyDown = (event: KeyboardEvent) => onA11yKeyDown(event, onClick)

  const renderIndicator = () => {
    if (experimentalIndicator) return experimentalIndicator

    return (
      <Icon
        name={favourited ? HeartFilled16 : Heart16}
        color={favourited ? Icon.Color.Warning : Icon.Color.GreyscaleLevel5}
        title={titleIcon}
        aria={ariaIcon}
        testId={favourited ? 'favourite-filled-icon' : 'favourite-icon'}
      />
    )
  }

  const isClickable = !!onClick

  const wrapperClassNames = classNames('u-flexbox u-align-items-center', {
    'u-cursor-pointer': isClickable,
  })

  return (
    <div
      className={wrapperClassNames}
      role="button"
      tabIndex={0}
      onClick={onClick}
      onKeyDown={handleKeyDown}
      data-testid={getTestId(testId, 'favourite')}
    >
      {renderIndicator()}
      <Spacer orientation={Spacer.Orientation.Vertical} size={Spacer.Size.XSmall} />
      <Text
        type={Text.Type.Caption}
        text={count}
        testId={getTestId(testId, 'favourite-count')}
        as="span"
      />
    </div>
  )
}

export default ItemBoxFavourite
