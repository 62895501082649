import { ReactNode } from 'react'
import {
  AuthenticityDiamondMultichome16 as SmallDiamond,
  AuthenticityDiamondMultichrome24 as MediumDiamond,
  AuthenticityDiamond32 as BigDiamond,
  VasBump32 as BumpRecommendation,
} from '@vinted/multichrome-icons'

export const replaceVariable = (
  template: string,
  variableName: string,
  replacement: ReactNode,
): Array<ReactNode | string> => {
  const regex = new RegExp(`(\\{${variableName}\\})`, 'g')
  const parts = template.split(regex) as Array<ReactNode>

  for (let index = 1; index < parts.length; index += 2) {
    parts[index] = replacement
  }

  return parts
}

export const getIconByName = (icon: string) => {
  if (icon === `${SmallDiamond.Title}${SmallDiamond.Size}`) return SmallDiamond
  if (icon === `${MediumDiamond.Title}${MediumDiamond.Size}`) return MediumDiamond
  if (icon === `${BigDiamond.Title}${BigDiamond.Size}`) return BigDiamond
  if (icon === `${BumpRecommendation.Title}${BumpRecommendation.Size}`) return BumpRecommendation

  return null
}
