'use client'

import { Animation } from '@vinted/web-ui'

import { RenderFavouriteArgsType } from 'components/ItemBox/ItemBox'
import ItemBoxFavourite from 'components/ItemBox/ItemBoxFavourite'
import useAsset from 'hooks/useAsset'

const PrideFavouriteButton = ({ favourite: props }: RenderFavouriteArgsType) => {
  const asset = useAsset('/assets/animations')

  function renderIndicator() {
    if (!props.favourited) return undefined

    return (
      <Animation
        size={Animation.Size.Small}
        animationUrl={asset('pride-heart16.json')}
        aria={props.ariaIcon}
        testId={props.testId ? `${props.testId}--favourite-icon` : undefined}
        loop={false}
      />
    )
  }

  return <ItemBoxFavourite {...props} experimentalIndicator={renderIndicator()} />
}

export default PrideFavouriteButton
