'use client'

import { ReactNode } from 'react'
import { Cell, Spacer, Text } from '@vinted/web-ui'
import { useSelector } from 'react-redux'
import classNames from 'classnames'

import { getTestId } from 'libs/utils/testId'
import { getIsFeatureSwitchEnabled } from 'state/feature-switches/selectors'

import ItemBoxFavourite from '../ItemBoxFavourite'

export type RenderFavouriteArgsType = {
  favourite: ComponentProps<typeof ItemBoxFavourite>
}

type Props = {
  renderPriceBreakdown?: ReactNode
  testId?: string
  bumpText?: ReactNode
  renderFavourite?: (args: RenderFavouriteArgsType) => ReactNode
  favourite?: ComponentProps<typeof ItemBoxFavourite> | null
  description?: { title: ReactNode; subtitle?: ReactNode } | null
  price: string
  oldPrice?: string | null
}

const InformationBreakdown = (props: Props) => {
  const {
    renderPriceBreakdown,
    testId,
    bumpText,
    renderFavourite: renderCustomFavourite,
    favourite,
    description,
    price,
    oldPrice,
  } = props

  const isProminenceV5Enabled = useSelector(getIsFeatureSwitchEnabled('bpf_prominence_v5'))

  const renderFavourite = () => {
    if (isProminenceV5Enabled) return null
    if (!favourite) return null
    if (renderCustomFavourite) return renderCustomFavourite({ favourite })

    return <ItemBoxFavourite testId={testId} {...favourite} />
  }

  const renderBumpedText = () => (
    <Text
      text={bumpText}
      theme="amplified"
      type={Text.Type.Caption}
      testId={getTestId(testId, 'bump-text')}
      as="p"
    />
  )

  const renderAskingPrice = () => (
    <div className="new-item-box__title" data-testid={getTestId(testId, 'title-container')}>
      <div className="title-content">
        <Text
          text={price}
          type={isProminenceV5Enabled ? Text.Type.Caption : Text.Type.Subtitle}
          theme={isProminenceV5Enabled ? 'muted' : 'amplified'}
          bold={!isProminenceV5Enabled}
          testId={getTestId(testId, 'price-text')}
          as="p"
        />
        {oldPrice ? (
          <>
            <Spacer orientation={Spacer.Orientation.Vertical} size={Spacer.Size.Small} />
            <Text
              text={oldPrice}
              type={isProminenceV5Enabled ? Text.Type.Caption : Text.Type.Subtitle}
              theme="muted"
              strikethrough
              truncate
              testId={getTestId(testId, 'old-price-text')}
              as="p"
            />
          </>
        ) : null}
      </div>
    </div>
  )

  const renderDescription = () => {
    if (!description) return null

    return (
      <Cell styling={Cell.Styling.Tight} testId={getTestId(testId, 'description')}>
        <div
          className={classNames('new-item-box__description', isProminenceV5Enabled && 'u-flexbox')}
        >
          <Text
            text={description.title}
            type={Text.Type.Caption}
            testId={getTestId(testId, 'description-title')}
            as="p"
            truncate={isProminenceV5Enabled}
          />
          {isProminenceV5Enabled && bumpText && renderBumpedText()}
        </div>
        <div className="new-item-box__description">
          <Text
            text={description.subtitle}
            type={Text.Type.Caption}
            testId={getTestId(testId, 'description-subtitle')}
            as="p"
          />
        </div>
      </Cell>
    )
  }

  if (isProminenceV5Enabled) {
    return (
      <div>
        <div className="u-flexbox u-align-items-flex-start u-ui-padding-bottom-regular">
          <div className="u-min-width-none u-flex-grow">{description && renderDescription()}</div>
        </div>
        <div>
          {renderAskingPrice()}
          {renderPriceBreakdown}
        </div>
      </div>
    )
  }

  return (
    <>
      <Cell styling={Cell.Styling.Tight} suffix={renderFavourite()}>
        {renderAskingPrice()}
      </Cell>
      <div className="u-flexbox u-align-items-flex-end">
        <div className="u-min-width-none u-flex-grow">
          {renderPriceBreakdown}
          {description && renderDescription()}
        </div>
        {bumpText && <div className="u-padding-left-small">{renderBumpedText()}</div>}
      </div>
    </>
  )
}

export default InformationBreakdown
