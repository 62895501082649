'use client'

import { useState } from 'react'
import { Cell, Text } from '@vinted/web-ui'
import { useSelector } from 'react-redux'

import OfflineVerificationBuyerModal from 'components/OfflineVerificationBuyerModal'
import { IconExplanationInnerDetailsModel } from 'types/models'
import { clickEvent } from 'libs/common/event-tracker/events'
import { formatCurrencyAmount } from 'libs/utils/formatString'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import useTracking from 'hooks/useTracking'
import { getLocale } from 'state/intl/selectors'

import { replaceVariable } from '../utils'

type Props = {
  iconBadge: IconExplanationInnerDetailsModel
  prefix: React.ReactNode
}

const IvsExplanation = ({ iconBadge, prefix }: Props) => {
  const { title, description, linkText, verificationFee } = iconBadge
  const { track } = useTracking()

  const [isVerificationModalOpen, setIsVerificationModalOpen] = useState(false)
  const locale = useSelector(getLocale)

  const handleClose = () => {
    setIsVerificationModalOpen(false)
  }

  const handleOpen = () => {
    setIsVerificationModalOpen(true)

    track(
      clickEvent({
        target: ClickableElement.PhysicalAuthenticityBuyer,
        screen: 'icons_explanation',
      }),
    )
  }

  const body = replaceVariable(
    description,
    'link',
    <button type="button" onClick={handleOpen}>
      <Text text={linkText} as="span" clickable />
    </button>,
  )

  return (
    <Cell
      prefix={prefix}
      title={title}
      body={
        <>
          {body.map((element, index) => (
            <span key={`icon-description-element-${index}`}>{element}</span>
          ))}
          <OfflineVerificationBuyerModal
            show={isVerificationModalOpen}
            onClose={handleClose}
            verificationFee={verificationFee && formatCurrencyAmount(verificationFee, locale)}
          />
        </>
      }
    />
  )
}

export default IvsExplanation
