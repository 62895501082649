'use client'

import { PropsWithChildren } from 'react'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import { InView } from 'react-intersection-observer'

import { abTestExposeEvent } from 'libs/common/event-tracker/events'
import useTracking from 'hooks/useTracking'
import { getExposee } from 'state/session/selectors'
import { getAbTestByName } from 'state/ab-tests/selectors'
import { getSessionStorageItem, setSessionStorageItem } from 'libs/utils/sessionStorage'
import { getIsFeatureSwitchEnabled } from 'state/feature-switches/selectors'
import { IconBadgeModel } from 'types/models'

const IVS_BADGE_EXPOSED_DAY_KEY = 'ivs_badge_test_exposed_day'

type Props = {
  iconBadges: Array<IconBadgeModel> | undefined
}

const IvsBadgeExposeObserver = ({ children, iconBadges }: PropsWithChildren<Props>) => {
  const { track } = useTracking()
  const exposee = useSelector(getExposee)
  const abTest = useSelector(getAbTestByName('ivs_badge'))
  const isIvsBadgeTrackingEnabled = useSelector(
    getIsFeatureSwitchEnabled('web_ivs_badge_exposure_tracking'),
  )
  const isItemEligibleForVerification = !isEmpty(iconBadges)

  if (!abTest || !isItemEligibleForVerification || !isIvsBadgeTrackingEnabled) {
    return children
  }

  const handleEnter = (inView: boolean) => {
    if (!inView) return

    const dateToday = new Date().toLocaleDateString()
    const lastExposureDate = getSessionStorageItem(IVS_BADGE_EXPOSED_DAY_KEY)

    if (lastExposureDate === dateToday) return

    setSessionStorageItem(IVS_BADGE_EXPOSED_DAY_KEY, dateToday)
    track(abTestExposeEvent({ ...exposee, ...abTest }))
  }

  return <InView onChange={handleEnter}>{children}</InView>
}

export default IvsBadgeExposeObserver
