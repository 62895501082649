'use client'

import { ComponentProps, ReactNode, MouseEvent } from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import { Badge, Cell, Image, Spacer, Text } from '@vinted/web-ui'

import { getTestId } from 'libs/utils/testId'
import { IconBadgeModel } from 'types/models'
import useAbTest from 'hooks/useAbTest'

import { getIsFeatureSwitchEnabled } from 'state/feature-switches/selectors'

import IvsBadgeExposeObserver from './IvsBadgeExposeObserver'
import ItemBoxFavourite from './ItemBoxFavourite'
import ItemBoxOwner from './ItemBoxOwner'
import ItemBoxMessage from './ItemBoxMessage'
import ItemBoxIconBadges from './ItemBoxIconBadges'
import ItemBoxFavouriteIcon from './ItemBoxFavouriteIcon'
import InformationBreakdown from './InformationBreakdown'

export type RenderFavouriteArgsType = {
  favourite: ComponentProps<typeof ItemBoxFavourite>
}

type Props = {
  id: number
  price: string
  favourite?: ComponentProps<typeof ItemBoxFavourite> | null
  oldPrice?: string | null
  url?: string
  badge?: ComponentProps<typeof Badge> | null
  owner?: ComponentProps<typeof ItemBoxOwner> | null
  description?: { title: ReactNode; subtitle?: ReactNode } | null
  image?: string | null
  /**
   * Sets the background color on an image element.
   * Accepts any legal CSS color values (Hexadecimal, RGB, predefined names etc.).
   */
  imageColor?: string | null
  imageRatio?: ComponentProps<typeof Image>['ratio']
  imagesExperimental?: Array<string>
  imagesExperimentalOverlayDisabled?: boolean
  alt?: string
  /**
   * Accepts custom favourite element.
   * `favourite` has to be defined for this property to take effect.
   */
  renderFavourite?: (args: RenderFavouriteArgsType) => ReactNode
  renderFooter?: ReactNode
  iconBadges?: Array<IconBadgeModel>
  renderPriceBreakdown?: ReactNode
  onClick?: (event: MouseEvent) => void
  status?: ComponentProps<typeof ItemBoxMessage> | null
  bumpText?: ReactNode
  /**
   * Adds data-testid attribute to parent and children components.
   * When used, --badge, --selected-icon, --image, --overlay-link,
   * --price-text, --old-price-text, --bump-text,
   * --footer, --favourite, --owner, --description, --status suffixes applied accordingly.
   */
  testId?: string
  secondaryBadgeExperimental?: ComponentProps<typeof Badge> | null
  informationCellCssClasses?: string
  hasFavouritedChanged?: boolean
}

const MIN_COLLAGE_IMAGES_COUNT = 3

const ItemBox = ({
  price,
  favourite,
  oldPrice,
  url,
  badge,
  owner,
  description,
  image,
  imageColor,
  imageRatio = Image.Ratio.Portrait,
  imagesExperimental,
  imagesExperimentalOverlayDisabled = false,
  alt,
  renderFavourite,
  renderFooter,
  iconBadges,
  renderPriceBreakdown,
  onClick,
  status,
  bumpText,
  testId,
  secondaryBadgeExperimental,
  informationCellCssClasses,
  id,
  hasFavouritedChanged,
}: Props) => {
  const ivsBadgeAbTest = useAbTest({ abTestName: 'ivs_badge' })
  const bumpRecommendationsTest = useAbTest({ abTestName: 'bump_recommendations' })

  const isProminenceV5Enabled = useSelector(getIsFeatureSwitchEnabled('bpf_prominence_v5'))

  const isIvsIconBadgesEnabled = ivsBadgeAbTest?.variant === 'a' && iconBadges
  const isBumpRecommendationsEnabled =
    bumpRecommendationsTest && bumpRecommendationsTest?.variant !== 'off' && iconBadges

  const renderFavouriteIcon = () => {
    if (!isProminenceV5Enabled) return null
    if (!favourite) return null

    return (
      <ItemBoxFavouriteIcon
        testId={testId}
        {...favourite}
        hasFavouritedChanged={hasFavouritedChanged}
        hasOverlay={!!status}
      />
    )
  }

  const renderBadges = () => {
    return (
      <div data-testid={getTestId(testId, 'badge-container')}>
        <Cell styling={Cell.Styling.Narrow} theme="transparent">
          <div className="u-flexbox u-flex-direction-column u-align-items-flex-start u-gap-small">
            {badge ? <Badge testId={getTestId(testId, 'badge')} {...badge} /> : null}
            {secondaryBadgeExperimental ? (
              <Badge
                testId={getTestId(testId, 'secondary-badge')}
                {...secondaryBadgeExperimental}
              />
            ) : null}
            {(isBumpRecommendationsEnabled || isIvsIconBadgesEnabled) && (
              <ItemBoxIconBadges
                iconBadges={iconBadges}
                testId={testId}
                itemId={Number(id)}
                onItemClick={onClick}
              />
            )}
          </div>
        </Cell>
      </div>
    )
  }

  const renderOverlay = () => {
    const showBadges = badge || secondaryBadgeExperimental || iconBadges

    return (
      <>
        {showBadges && renderBadges()}
        {status ? <ItemBoxMessage testId={testId} {...status} /> : null}
      </>
    )
  }

  const renderExperimentalImagesCollage = () => {
    if (!imagesExperimental || imagesExperimental.length < MIN_COLLAGE_IMAGES_COUNT) return null

    const showAdditionalImagesOverlay =
      imagesExperimental.length > MIN_COLLAGE_IMAGES_COUNT && !imagesExperimentalOverlayDisabled
    const additionalImagesCount = imagesExperimental.length - MIN_COLLAGE_IMAGES_COUNT + 1

    return (
      <div className="new-item-box__collage" data-testid={getTestId(testId, 'collage')}>
        <div className="new-item-box__image collage--image-1">
          <Image
            src={imagesExperimental[0]}
            scaling={Image.Scaling.Cover}
            ratio={imageRatio}
            color={imageColor}
            alt={alt}
            testId={getTestId(testId, 'image-1')}
          />
        </div>
        <div className="new-item-box__image collage--image-2">
          <Image
            src={imagesExperimental[1]}
            scaling={Image.Scaling.Cover}
            ratio={imageRatio}
            color={imageColor}
            alt={alt}
            testId={getTestId(testId, 'image-2')}
          />
        </div>
        <div className="new-item-box__image collage--image-3">
          <Image
            src={imagesExperimental[2]}
            scaling={Image.Scaling.Cover}
            ratio={imageRatio}
            color={imageColor}
            alt={alt}
            testId={getTestId(testId, 'image-3')}
          />
          {showAdditionalImagesOverlay ? (
            <div className="new-item-box__image-overlay">
              <Text
                type={Text.Type.Heading}
                theme="inverse"
                text={`+${additionalImagesCount}`}
                as="span"
              />
            </div>
          ) : null}
        </div>
      </div>
    )
  }

  const renderImage = () => {
    return (
      <div className="new-item-box__image">
        <Image
          src={image}
          scaling={Image.Scaling.Cover}
          ratio={imageRatio}
          color={imageColor}
          alt={alt}
          testId={getTestId(testId, 'image')}
        />
      </div>
    )
  }

  const itemBoxOverlayClasses = classNames('new-item-box__overlay', {
    'new-item-box__overlay--clickable': !!onClick || !!url,
  })

  return (
    <IvsBadgeExposeObserver iconBadges={iconBadges}>
      <div className="new-item-box__container" data-testid={testId}>
        {owner ? <ItemBoxOwner testId={testId} {...owner} /> : null}

        <div className="u-position-relative u-min-height-none u-flex-auto">
          {renderExperimentalImagesCollage() || renderImage()}
          {renderFavouriteIcon()}
          {url ? (
            <a
              href={url}
              className={itemBoxOverlayClasses}
              onClick={onClick}
              data-testid={getTestId(testId, 'overlay-link')}
              title={alt}
            >
              {renderOverlay()}
            </a>
          ) : (
            <div className={itemBoxOverlayClasses} onClick={onClick} aria-hidden="true">
              {renderOverlay()}
            </div>
          )}
        </div>

        <div className={informationCellCssClasses}>
          <Cell styling={informationCellCssClasses ? Cell.Styling.Tight : Cell.Styling.Narrow}>
            <InformationBreakdown
              description={description}
              price={price}
              oldPrice={oldPrice}
              testId={testId}
              favourite={favourite}
              renderPriceBreakdown={renderPriceBreakdown}
              bumpText={bumpText}
              renderFavourite={renderFavourite}
            />
            {renderFooter && (
              <div data-testid={getTestId(testId, 'footer')}>
                <Spacer />
                {renderFooter}
              </div>
            )}
          </Cell>
        </div>
      </div>
    </IvsBadgeExposeObserver>
  )
}

export default ItemBox
