'use client'

import { MouseEvent } from 'react'
import { Button, Cell, Icon, Image, Modal, Navigation, Text } from '@vinted/web-ui'
import { X24 } from '@vinted/monochrome-icons'

import useTranslate from 'hooks/useTranslate'
import useBreakpoint from 'hooks/useBreakpoint'
import { ITEM_URL } from 'constants/routes'
import { IconExplanationInnerDetailsModel } from 'types/models'
import Notification from 'components/Notification'
import { toUrlQuery } from 'libs/utils/url'
import { useHomeContext } from 'pages/Home/HomeProvider'

import IvsExplanation from './IvsExplanation'
import useIconsExplanations from '../hooks/useIconsExplanation'
import { getIconByName } from '../utils'

const MODAL_DESKTOP_WIDTH = '343px'

type Props = {
  show: boolean
  onClose: () => void
  itemId: number
  onItemClick?: (event: MouseEvent) => void
}

const IconsExplanationModal = ({ show, onClose, itemId, onItemClick }: Props) => {
  const translate = useTranslate('item_box.icons_explanation_modal')
  const errorsTranslate = useTranslate('errors')
  const breakpoints = useBreakpoint()
  const { homepageSessionId } = useHomeContext()

  const { iconBadges, isNotificationDisplayed, isOpen, itemDetails, handleNotificationClose } =
    useIconsExplanations(itemId, show)

  function getIconExplanation(iconBadge: IconExplanationInnerDetailsModel) {
    const icon = getIconByName(iconBadge.icon)

    if (!icon) return null

    return (
      <IvsExplanation iconBadge={iconBadge} prefix={<Icon name={icon} />} key={iconBadge.icon} />
    )
  }

  function renderIconsExplanation() {
    if (!iconBadges.length) return null

    return iconBadges.map(getIconExplanation)
  }

  const stopPropagation = (event: MouseEvent) => {
    event.stopPropagation()
  }

  const contentDimensions = breakpoints.phones ? {} : { width: MODAL_DESKTOP_WIDTH }

  return (
    <div onClick={stopPropagation} role="none">
      <Modal
        isFramed={false}
        show={isOpen}
        testId="icons-explanation-modal"
        contentDimensions={contentDimensions}
        defaultCallback={onClose}
        closeOnOverlay
      >
        <Navigation
          body={translate('navigation_title')}
          right={
            <Button
              inline
              iconName={X24}
              styling={Button.Styling.Flat}
              onClick={onClose}
              testId="icons-explanation-modal-navigation-close-button"
            />
          }
        />
        <div className="new-item-box_icons-explanation-modal-body">
          <Cell
            prefix={
              <Image
                size={Image.Size.X2Large}
                ratio={Image.Ratio.SmallPortrait}
                src={itemDetails?.photo.url}
              />
            }
            title={
              <p className="new-item-box_icons-explanation-modal-title">{itemDetails?.title}</p>
            }
            body={
              <span>
                {itemDetails?.size && <Text text={`${itemDetails.size} ·`} />}{' '}
                <a href={itemDetails?.brandDetails.url}>
                  <Text text={itemDetails?.brandDetails.title} underline as="span" />
                </a>
              </span>
            }
          />
          {renderIconsExplanation()}
        </div>
        <Cell
          body={
            <Button
              text={translate('actions.see_item_details_cta')}
              styling={Button.Styling.Filled}
              testId="icons-explanation-modal-button"
              onClick={onItemClick}
              url={
                homepageSessionId
                  ? `${ITEM_URL(itemId)}?${toUrlQuery({ homepage_session_id: homepageSessionId })}`
                  : ITEM_URL(itemId)
              }
            />
          }
        />
      </Modal>
      {isNotificationDisplayed && (
        <Notification
          body={errorsTranslate('generic')}
          onClose={handleNotificationClose}
          testId="icons-explanation-modal-error-notification"
        />
      )}
    </div>
  )
}

export default IconsExplanationModal
