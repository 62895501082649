import { useSelector } from 'react-redux'

import useTranslate from 'hooks/useTranslate'
import { ProductItemModel } from 'types/models'
import { getIsFeatureSwitchEnabled } from 'state/feature-switches/selectors'

type Props = {
  item: ProductItemModel
  isDescriptionHidden?: boolean
  viewingSelf: boolean
}

type ItemDescription = { title: string; subtitle: string } | null

const useItemDescription = (props: Props): ItemDescription => {
  const { item, isDescriptionHidden, viewingSelf } = props

  const isProminenceV5Enabled = useSelector(getIsFeatureSwitchEnabled('bpf_prominence_v5'))
  const translate = useTranslate()

  if (isDescriptionHidden) return null

  const getSellerDescription = () => {
    if (item.isDraft) {
      return { title: '', subtitle: '' }
    }

    const title =
      item.viewCount === undefined
        ? item.sizeTitle
        : translate('item.view_count', { count: item.viewCount }, { count: item.viewCount })

    const subtitle = translate(
      'item.favourite_count',
      { count: item.favouriteCount },
      { count: item.favouriteCount },
    )

    return { title, subtitle }
  }

  if (isProminenceV5Enabled) {
    const getBuyerDescription = () => ({
      title: item.brandTitle === '' ? item.title : item.brandTitle,
      subtitle: item.sizeTitle === '' ? item.status : item.sizeTitle,
    })

    return viewingSelf ? getSellerDescription() : getBuyerDescription()
  }

  if (viewingSelf) {
    return getSellerDescription()
  }

  return {
    title: item.sizeTitle,
    subtitle: item.brandTitle,
  }
}

export default useItemDescription
