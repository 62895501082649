'use client'

import { ReactNode, useState, MouseEvent } from 'react'
import { Icon } from '@vinted/web-ui'

import { getTestId } from 'libs/utils/testId'
import { IconBadgeModel } from 'types/models'
import { clickEvent } from 'libs/common/event-tracker/events'
import { ClickableElement as Target } from 'constants/tracking/clickable-elements'
import useTracking from 'hooks/useTracking'

import IconsExplanationModal from '../IconsExplanationModal'
import { getIconByName } from '../utils'

type ClickableIconProps = {
  element: ReactNode
  itemId: number
  onItemClick?: (event: MouseEvent) => void
}

const ClickableElement = ({ element, itemId, onItemClick }: ClickableIconProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const { track } = useTracking()

  const onModalOpen = (event: MouseEvent) => {
    setIsOpen(true)
    track(
      clickEvent({
        target: Target.IconsExplanation,
      }),
    )

    event.preventDefault()
    event.stopPropagation()
  }

  const closeExplanationModal = () => {
    setIsOpen(false)
  }

  return (
    <>
      <button type="button" onClick={onModalOpen} data-testid="clickable-icon">
        {element}
      </button>
      <IconsExplanationModal
        show={isOpen}
        onClose={closeExplanationModal}
        itemId={itemId}
        onItemClick={onItemClick}
      />
    </>
  )
}

type IconBadgeProps = {
  iconBadges: Array<IconBadgeModel>
  testId?: string
  itemId: number
  onItemClick?: (event: MouseEvent) => void
}

const ItemBoxIconBadges = ({ iconBadges, testId, itemId, onItemClick }: IconBadgeProps) => {
  if (!iconBadges?.length) return null

  return iconBadges.map(iconBadge => {
    const iconName = getIconByName(iconBadge.iconBig)

    if (!iconName) return null

    const icon = <Icon name={iconName} aria={{ 'aria-label': iconBadge.label }} />

    const renderBadge = () =>
      iconBadge.label?.includes('Bump') ? (
        icon
      ) : (
        <ClickableElement element={icon} itemId={itemId} onItemClick={onItemClick} />
      )

    return (
      <div
        data-testid={getTestId(testId, `overlay-icon-${iconBadge.iconBig}`)}
        key={iconBadge.iconBig}
        className="new-item-box__overlay-icon"
      >
        {renderBadge()}
      </div>
    )
  })
}

export default ItemBoxIconBadges
