'use client'

import { Cell, Image, Text } from '@vinted/web-ui'

import useTracking from 'hooks/useTracking'

import { getTestId } from 'libs/utils/testId'
import { profileClickEvent } from 'libs/common/event-tracker/events'
import { ClickableElement } from 'constants/tracking/clickable-elements'

type Props = {
  id: number
  name: string
  image: string
  alt?: string
  url: string
  urlProps?: JSX.IntrinsicElements['a'] & Record<`data-${string}`, unknown>
  /**
   * Adds data-testid attribute to children components.
   * When used, --owner and --owner-image suffixes applied accordingly.
   */
  testId?: string
}

const ItemBoxOwner = ({ id: userId, name, image, url, alt, urlProps, testId }: Props) => {
  const { track } = useTracking()

  const handleOnClick = () => {
    track(
      profileClickEvent({
        target: ClickableElement.ItemBoxUserProfileLink,
        ownerId: userId.toString(),
      }),
    )
  }

  return (
    <div data-testid={getTestId(testId, 'owner')}>
      <Cell
        styling={Cell.Styling.Narrow}
        prefix={
          <Image
            src={image}
            alt={alt}
            styling={Image.Styling.Circle}
            size={Image.Size.Regular}
            testId={getTestId(testId, 'owner-image')}
          />
        }
        url={url}
        urlProps={urlProps}
        onClick={handleOnClick}
      >
        <div className="u-flexbox">
          <Text
            text={name}
            type={Text.Type.Caption}
            testId={getTestId(testId, 'owner-name')}
            as="p"
          />
        </div>
      </Cell>
    </div>
  )
}

export default ItemBoxOwner
