import { noop } from 'lodash'
import { createContext } from 'react'

export type HomeContextType = {
  homepageSessionId: string | undefined
  setHomepageSessionId: (sessionId: string) => void
}

const HomeContext = createContext<HomeContextType>({
  homepageSessionId: undefined,
  setHomepageSessionId: noop,
})

export default HomeContext
